
import MpxTextField from "@mekari/pixel/src/js/components/TextField/index.vue";
import MpxLabel from "@mekari/pixel/src/js/components/Label/index.vue";
import MpxButton from "@mekari/pixel/src/js/components/Button/index.vue";
import { defineComponent } from "vue";
import { generateHeaders } from "@/utils/util";
import { mixpanelInstance } from "@/utils/mixpanel";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/hmac-validator";

type HMACState = {
  [key: string]: any;
  clientId: string;
  clientSecret: string;
  httpMethod: string;
  url: string;
  date: string;
};

type ErrorState = {
  [key: string]: any;
  url: boolean;
  date: boolean;
};

type InitialState = {
  headers: any;
  hmac: HMACState;
  error: ErrorState;
};

export default defineComponent({
  name: "hmac-validator",
  components: {
    MpxButton,
    MpxTextField,
    MpxLabel,
  },
  data: function (): InitialState {
    return {
      headers: "",
      hmac: {
        clientId: "",
        clientSecret: "",
        httpMethod: "get",
        url: "",
        date: "",
      },
      error: {
        url: false,
        date: false,
      },
    };
  },
  methods: {
    handleInput(e: Event): void {
      // makse sure the event from component return value as event object
      if (typeof e === "object") {
        e.stopImmediatePropagation(); // make sure stop bubbling event capture
        const element: HTMLInputElement = e.target as HTMLInputElement;
        const id: string = element.id; // get id of element

        // get data attribute of validate - this attribute to flag the element should be validated
        const isValidate = !!element.dataset.validate;

        // get type of element input
        const type: string = element.dataset.type as string;
        const keyName: string = id.split("-")[1];
        this.hmac[keyName] = element.value;

        if (isValidate) {
          const isError = this.validate(type, element.value);
          this.error[type] = isError;
        }
      }
    },
    handleSubmit(): void {
      for (const key in this.hmac) {
        const isError: boolean = this.validate(key, this.hmac[key]);
        this.error[key] = isError;
      }

      if (this.error.url || this.error.date) return;

      const pathUrl: URL = new URL(this.hmac.url);
      const headers = generateHeaders({
        clientId: this.hmac.clientId,
        clientSecret: this.hmac.clientSecret,
        path: pathUrl.pathname + pathUrl.search,
        method: this.hmac.httpMethod,
        dateTime: this.hmac.date,
      });
      this.headers = headers;
    },
    getError(keyName: string): boolean {
      return this.error[keyName];
    },
    validate(type: string, value: string): boolean {
      this.headers = "";
      const patternUrl =
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
      const patternDateHeader = value.split(" ");
      const isValid =
        patternDateHeader.length === 6 &&
        (patternDateHeader.includes("GMT") ||
          patternDateHeader.includes("gmt"));
      switch (type) {
        case "url":
          return !patternUrl.test(value);
        case "date":
          return !isValid;

        default:
          return true;
      }
    },
  },
});
