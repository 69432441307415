<template>
  <div class="page-title">
    <h1>HMAC Validator</h1>
  </div>
  <form class="form-container" @submit.prevent="handleSubmit">
    <MpxLabel class="label-container"> Client ID </MpxLabel>
    <MpxTextField
      class="input-container"
      id="clientId"
      prefix="mpx-"
      variant="text-field"
      :value="hmac.clientId"
      @input="handleInput"
      placeholder="Your application client ID"
    />

    <MpxLabel> Client Secret </MpxLabel>
    <MpxTextField
      class="input-container"
      id="clientSecret"
      prefix="mpx-"
      variant="text-field"
      :value="hmac.clientSecret"
      @input="handleInput"
      placeholder="Your application client Secret"
    />

    <MpxLabel> HTTP Method </MpxLabel>
    <select class="form-control input-container" v-model="hmac.httpMethod">
      <option value="get">GET</option>
      <option value="post">POST</option>
      <option value="patch">PATCH</option>
      <option value="put">PUT</option>
    </select>

    <MpxLabel> Full API URL </MpxLabel>
    <MpxTextField
      class="input-container"
      :error="getError('url')"
      id="url"
      data-type="url"
      data-validate="true"
      prefix="mpx-"
      :value="hmac.url"
      variant="text-field"
      placeholder="https://api.mekari.com/v2/talenta/v2/employee"
      @input="handleInput"
    />
    <MpxLabel v-if="getError('url')"> URL is invalid </MpxLabel>
    <br />

    <MpxLabel> Date Header Value </MpxLabel>
    <MpxTextField
      class="input-container"
      :error="getError('date')"
      id="date"
      data-type="date"
      data-validate="true"
      prefix="mpx-"
      :value="hmac.date"
      variant="text-field"
      placeholder="Wed, 10 Nov 2021 07:24:29 GMT"
      @input="handleInput"
    />
    <MpxLabel v-if="getError('date')">
      Invalid date format. Format must follow RFC7231. Example: Wed, 10 Nov 2021
      07:24:29 GMT
    </MpxLabel>
    <br />

    <MpxButton type="submit" label="Submit" />
    <br />
    <br />
    <MpxLabel v-if="headers !== ''">
      Correct Header HMAC:
      <br />
      <p>Authorization : {{ headers.headers.Authorization }}</p>
    </MpxLabel>
    <br />
    <MpxLabel v-if="headers !== ''"> cURL example </MpxLabel>
  </form>
  <pre v-if="headers !== ''">
        curl --request {{ hmac.httpMethod.toUpperCase() }} '{{ hmac.url }}' \
        --header 'Authorization: {{ headers.headers.Authorization }}' \
        --header 'Date: {{ hmac.date }}' \
        --header 'Content-Type: {{ headers.headers["Content-Type"] }}' \
        --data-raw ''
    </pre>
</template>

<script lang="ts">
import MpxTextField from "@mekari/pixel/src/js/components/TextField/index.vue";
import MpxLabel from "@mekari/pixel/src/js/components/Label/index.vue";
import MpxButton from "@mekari/pixel/src/js/components/Button/index.vue";
import { defineComponent } from "vue";
import { generateHeaders } from "@/utils/util";
import { mixpanelInstance } from "@/utils/mixpanel";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/hmac-validator";

type HMACState = {
  [key: string]: any;
  clientId: string;
  clientSecret: string;
  httpMethod: string;
  url: string;
  date: string;
};

type ErrorState = {
  [key: string]: any;
  url: boolean;
  date: boolean;
};

type InitialState = {
  headers: any;
  hmac: HMACState;
  error: ErrorState;
};

export default defineComponent({
  name: "hmac-validator",
  components: {
    MpxButton,
    MpxTextField,
    MpxLabel,
  },
  data: function (): InitialState {
    return {
      headers: "",
      hmac: {
        clientId: "",
        clientSecret: "",
        httpMethod: "get",
        url: "",
        date: "",
      },
      error: {
        url: false,
        date: false,
      },
    };
  },
  methods: {
    handleInput(e: Event): void {
      // makse sure the event from component return value as event object
      if (typeof e === "object") {
        e.stopImmediatePropagation(); // make sure stop bubbling event capture
        const element: HTMLInputElement = e.target as HTMLInputElement;
        const id: string = element.id; // get id of element

        // get data attribute of validate - this attribute to flag the element should be validated
        const isValidate = !!element.dataset.validate;

        // get type of element input
        const type: string = element.dataset.type as string;
        const keyName: string = id.split("-")[1];
        this.hmac[keyName] = element.value;

        if (isValidate) {
          const isError = this.validate(type, element.value);
          this.error[type] = isError;
        }
      }
    },
    handleSubmit(): void {
      for (const key in this.hmac) {
        const isError: boolean = this.validate(key, this.hmac[key]);
        this.error[key] = isError;
      }

      if (this.error.url || this.error.date) return;

      const pathUrl: URL = new URL(this.hmac.url);
      const headers = generateHeaders({
        clientId: this.hmac.clientId,
        clientSecret: this.hmac.clientSecret,
        path: pathUrl.pathname + pathUrl.search,
        method: this.hmac.httpMethod,
        dateTime: this.hmac.date,
      });
      this.headers = headers;
    },
    getError(keyName: string): boolean {
      return this.error[keyName];
    },
    validate(type: string, value: string): boolean {
      this.headers = "";
      const patternUrl =
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
      const patternDateHeader = value.split(" ");
      const isValid =
        patternDateHeader.length === 6 &&
        (patternDateHeader.includes("GMT") ||
          patternDateHeader.includes("gmt"));
      switch (type) {
        case "url":
          return !patternUrl.test(value);
        case "date":
          return !isValid;

        default:
          return true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";
.form-container {
  background-color: white;
  padding: 16px;
}

.input-container {
  margin-bottom: 12px;
}

pre {
  background-color: #f3f5f7;
  font-family: "Courier New", Courier, monospace;
  padding: 12px;
}

.page-title {
  padding: 22px 24px;
  background: #f3f5f7;

  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-slate;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $color-black;

    i {
      display: block;
      float: left;
      margin-right: 6px;
      margin-top: 6px;
    }
  }
}
</style>
