import CryptoJS from "crypto-js";

/**
 * Generate authentication headers based on method and path
 */
function generateHeaders({
  clientId = "",
  clientSecret = "",
  path = "",
  method = "GET",
  dateTime = "",
}) {
  const body = {
    // request body
  };

  const requestLine = `${method.toUpperCase()} ${path} HTTP/1.1`;
  const payload = [`date: ${dateTime}`, requestLine].join("\n");
  const { bodyDigest, signature } = hmacSha256Base64(
    clientSecret,
    payload,
    body
  );

  const header = {
    "Content-Type": "application/json",
    Date: dateTime,
    Authorization: `hmac username="${clientId}", algorithm="hmac-sha256", headers="date request-line", signature="${signature}"`,
    Digest: bodyDigest,
  };

  // Set method and path for the request
  const headers = {
    ...header,
    "X-Idempotency-Key": "1234",
  };

  const options = {
    method: method,
    url: `${path}`,
    headers: {
      ...headers,
    },
    body,
  };

  return options;
}

function hmacSha256Base64(
  secret: string,
  message?: any,
  body?: any
): {
  bodyDigest: string;
  signature: string;
} {
  const digest = CryptoJS.HmacSHA256(message, secret);
  const signature = CryptoJS.enc.Base64.stringify(digest);

  let bodyDigest: string = JSON.stringify(body) || "";
  if (body) {
    const bodyLen = Object.keys(body).length;
    if (bodyLen > 0) {
      const bodyHash = CryptoJS.SHA256(body);
      bodyDigest = "SHA-256=" + CryptoJS.enc.Base64.stringify(bodyHash);
    }
  }

  return {
    bodyDigest,
    signature,
  };
}

export { generateHeaders };
